require("bootstrap/dist/css/bootstrap.css");
require("../css/base.css");
require("../css/styles.css");
require("../css/fonts.css");

window.onload = function () {
    var body = document.getElementsByTagName("body");
    body[0].style.removeProperty("background");

    var wrapper = document.getElementById("wrapper");
    if (wrapper) {
        wrapper.style.removeProperty("display");
    }
};
